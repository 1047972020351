// middleware/auth.js

function getToken() {
    return localStorage.getItem("accessToken");

}
const requireAuth = (to, from, next) => {
    const token = getToken(); // Obtenha o token do armazenamento local

    // Verifique se o token está presente e válido
    if (!token) {
        // Se o token não estiver presente, redirecione para a página de login
        next({ name: "Signin" });
    } else {
        // O token está presente, continue navegando
        next();
    }
};

export default requireAuth;