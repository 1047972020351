<template>
  <transition name="fade">
    <div v-if="notification.show" class="notification" :class="notification.type">
      {{ notification.message }}
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: mapState(['notification']),
};
</script>

<style scoped>
/* Estilos base para a notificação */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 5px;
  color: white;
  z-index: 99999;
  transition: opacity 0.5s; /* Define a duração da transição */
}

.notification.success {
  background-color: #4CAF50;
}
.notification.error {
  background-color: #ff7043;
}

/* Classes de transição */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* <= Nome da classe no Vue 2.x é .fade-leave-active */ {
  opacity: 0;
}
</style>
