<template>
  <div class="card mb-4">
    <div class="card-header pb-0 pb-0 d-flex justify-content-between align-items-center">
      <h6>Meus Usuários</h6>
      <!-- botao a direita para dicionar novos -->
      <argon-button
        variant="gradient"
        color="primary"
        @click="goToInsertUser"
        >
        <i class="fas fa-plus"></i> Adicionar Usuário
      </argon-button>
    </div>
    <div class="card-body px-0 pt-0 pb-2 m-3">
      <div class="license-info p-3" style="background: #ededed" v-if="reseller_data.id">
        <h6 class="mb-0 text-dark">Status da Licença</h6>
        <p class="text-xs font-weight-bold">Você tem <span class="text-primary">{{ reseller_data.activeLicenses }} Licenças Ativas</span> e está utilizando <span class="text-danger">{{ reseller_data.activeUsersCount }} Licenças</span>.</p>
        <p class="text-xs">Precisa de mais? Entre em contato para adquirir licenças adicionais.</p>
      </div>
      <div class="table-responsive p-0">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Id
              </th>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Nome
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                E-mail
              </th>


              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
              >
                Utilização
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>
              <div>
                <img
                    src="../../assets/img/small-logos/icone_reduzido_logo.png"
                    class="avatar avatar-sm rounded-circle me-2"
                    alt="spotify"
                />
              </div>
              <div class="my-auto">
                <h6 class="mb-0 text-sm">{{ user.id }}</h6>
              </div>
            </td>
            <td>
              <div v-if="(user.type=='6' || user.type=='5')">
                <p class="text-sm font-weight-bold mb-0">Temporário</p>

              </div>
              <div v-else>
                <p class="text-sm font-weight-bold mb-0">Ativo</p>

              </div>
            </td>
            <td>
              <p class="text-sm font-weight-bold mb-0">{{ user.companyName }}</p>
            </td>
              <td>

                <p class="text-sm font-weight-bold mb-0">{{ user.email }}</p>
              </td>


              <td class="align-middle text-center">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="me-2 text-xs font-weight-bold">{{ user.messageCount / 50 * 100 }}%</span>
                  <div>
                    <div class="progress">
                      <div
                          :class="[
              'progress-bar',
              user.messageCount <= 10 ? 'bg-danger' :
              user.messageCount <= 30 ? 'bg-warning' : 'bg-success'
            ]"
                          role="progressbar"
                          :aria-valuenow="user.messageCount"
                          aria-valuemin="0"
                          aria-valuemax="50"
                          :style="{ width: `${user.messageCount / 50 * 100}%` }"
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="align-middle">
                <argon-button v-if="user.type=='temporary'" variant="gradient"
                              color="info"
                              @click="activateLicense(user.id)"
                              class="m-1 " >
                  <!-- icone de editar -->
                  <i class="fas fa-check"></i> Ativar Licensa
                </argon-button>
                <argon-button v-else variant="gradient"
                              color="info"
                              @click="removeLicense(user.id)"
                              class="m-1 " >
                  <!-- icone de editar -->
                  <i class="fas fa-check"></i> Desativar Licensa
                </argon-button>
                <argon-button
                  variant="gradient"
                  color="info"
                  @click="goToEditUser(user.id)"
                  class="m-1 btn-icon-only " >
                  <!-- icone de editar -->
                  <i class="fas fa-edit"></i>
                </argon-button>
                <argon-button
                    v-if="user.flg_blocked"
                    variant="gradient"
                    color="danger"
                    class="m-1  " @click="unblockUser(user.id)"  >
                  <!-- icone de remover -->
                  <i class="fas fa-unlock"></i> Desbloquear
                </argon-button>
                <argon-button
                    v-else
                    variant="gradient"
                    color="danger"
                    class="m-1  "  @click="blockUser(user.id)" >
                  <!-- icone de remover -->
                  <i class="fas fa-lock"></i> Bloquear
                </argon-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";
import apiClient from '../../axiosConfig.js';
const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;


import { ref } from "vue";
import { useStore } from "vuex";
const store = useStore();
const users = ref([]); // Armazena a lista de usuários
const isLoading = ref(false);
const errorMessage = ref('');
const reseller_data = ref({});
const goToEditUser = (user_id) =>
{
  router.push(`/cliente/${user_id}`);

};
const goToInsertUser = () =>
{
  router.push(`/cliente/`);
};
const listResellerUsers = async () => {
  try {
    isLoading.value = true;
    var resp = await apiClient.post(`${apiEndpoint}/api/reseller/list_users/`, {});
    if (resp.data.success && resp.data.users) {
      users.value = resp.data.users; // Atualiza a lista de usuários com os dados recebidos
    } else {
      // Handle error
      errorMessage.value = resp.data.error || 'Erro ao carregar os usuários.';
    }
    //location para o /principal
    //router.push("/principal");
  } catch (error) {
    errorMessage.value = 'Erro ao realizar a requisição.';
  } finally {
    isLoading.value = false;
  }
};
const getResellerData = async () => {
  try {
    isLoading.value = true;
    var resp = await apiClient.post(`${apiEndpoint}/api/reseller/get_data/`, {});
    if (resp.data.success && resp.data.reseller_data) {
      reseller_data.value = resp.data.reseller_data; // Atualiza a lista de usuários com os dados recebidos
    } else {
      // Handle error
      errorMessage.value = resp.data.error || 'Erro ao carregar os usuários.';
    }
    //location para o /principal
    //router.push("/principal");
  } catch (error) {
    errorMessage.value = 'Erro ao realizar a requisição.';
  } finally {
    isLoading.value = false;
  }
};
const blockUser = async (user_id) => {
  try {
    isLoading.value = true;
    var resp = await apiClient.post(`${apiEndpoint}/api/reseller/block_user/`, {user_id: user_id});

    store.dispatch('triggerNotification', {
      message: resp.data.message,
      type: 'success' // ou 'error' para erros
    });
    getResellerData();
    listResellerUsers();
  } catch (error) {
    errorMessage.value = 'Erro ao realizar a requisição.';
  } finally {
    isLoading.value = false;
  }
};
const unblockUser = async (user_id) => {
  try {
    isLoading.value = true;
    var resp = await apiClient.post(`${apiEndpoint}/api/reseller/unblock_user/`, {user_id: user_id});

    store.dispatch('triggerNotification', {
      message: resp.data.message,
      type: 'success' // ou 'error' para erros
    });
    getResellerData();
    listResellerUsers();
  } catch (error) {
    errorMessage.value = 'Erro ao realizar a requisição.';
  } finally {
    isLoading.value = false;
  }
};
//cria as funcoes de activateLicense e deactivateLicense
const activateLicense = async (user_id) => {
  try {
    isLoading.value = true;
    var resp = await apiClient.post(`${apiEndpoint}/api/reseller/activate_user_license/`, {user_id: user_id});
    if(resp.data.success)
    {
      store.dispatch('triggerNotification', {
        message: resp.data.message,
        type: 'success' // ou 'error' para erros
      });
      getResellerData();
      listResellerUsers();
    }
    else
    {
      store.dispatch('triggerNotification', {
        message: resp.data.error,
        type: 'error' // ou 'error' para erros
      });
    }
  } catch (error) {
    errorMessage.value = 'Erro ao realizar a requisição.';
  } finally {
    isLoading.value = false;
  }
};
const removeLicense = async (user_id) => {
  try {
    isLoading.value = true;
    var resp = await apiClient.post(`${apiEndpoint}/api/reseller/deactivate_user_license/`, {user_id: user_id});

    store.dispatch('triggerNotification', {
      message: resp.data.message,
      type: 'success' // ou 'error' para erros
    });
    getResellerData();
    listResellerUsers();
  } catch (error) {
    errorMessage.value = 'Erro ao realizar a requisição.';
  } finally {
    isLoading.value = false;
  }
};

getResellerData();
listResellerUsers();
</script>