<script setup>
import { onBeforeMount, onMounted, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import  router  from '../router';
const route = useRoute();
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";


const store = useStore();

onMounted(() => {
  store.state.isAbsolute = false;
  setNavPills();
  setTooltip();
  loadProfileData(); // Carregar dados apenas se estiver editando
});


const name = ref('');
const description = ref('');
const email = ref('');
const isLoading = ref(false);
const errorMessage = ref('');
import apiClient from '../axiosConfig.js';


// Função para carregar os dados do perfil
async function loadProfileData() {

  try {
    isLoading.value = true;
    const response = await apiClient.post("/api/reseller/get_data/", {

    });
    const data = response.data.reseller_data;
    email.value = data.email;
    description.value = data.description;
    name.value = data.name;

  } catch (error) {
    errorMessage.value = 'Erro ao carregar os dados.';
  } finally {
    isLoading.value = false;
  }
}

// Função para salvar os dados do perfil
const saveProfileData = async () => {
  try {
    isLoading.value = true;
    var payload = {
      email: email.value,
      description: description.value,
      name: name.value
    };
    await apiClient.post(`/api/reseller/save_reseller_profile/`, payload);
    store.dispatch('triggerNotification', {
      message: 'Operação realizada com sucesso!',
      type: 'success' // ou 'error' para erros
    });
  //location para o /principal
  //router.push("/principal");
  } catch (error) {
    errorMessage.value = 'Erro ao realizar a requisição.';
  } finally {
    isLoading.value = false;
  }
};

onBeforeMount(() => {
  store.state.imageLayout = "profile-overview";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
});
onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.imageLayout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
});
loadProfileData();
</script>
<template>

    <div class="py-4 container-sm">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Editar perfil</p>

              </div>
            </div>
            <div class="card-body">
              <form @submit.prevent="saveProfileData">
              <p class="text-uppercase text-sm">Informação da revenda</p>
              <div class="row">
                <div class="col-md-12">
                  <label for="email-input" class="form-control-label">E-mail</label>
                  <argon-input v-model="email" id="email-input" type="email" value="email@example.com" />
                </div>
                <div class="col-md-12">
                  <label for="name" class="form-control-label">Nome</label>
                  <argon-input v-model="name" id="name" type="text" value="" maxlength="200" />
                </div>
                <div class="col-md-12">
                  <label for="description" class="form-control-label">Descrição</label>
                  <textarea v-model="description" class="form-control" id="description" rows="3"></textarea>
                </div>
                <!-- botao salvar colspan de 3 -->
                <div class="col-md-4 mx-auto mt-4 col-span-3   ">
                  <argon-button :disabled="isLoading">
                    <span v-if="isLoading">Salvando...</span>
                        <span v-else>Salvar</span>
                      </argon-button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
</template>
