<script setup>
import { onBeforeMount, onMounted, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import  router  from '../router';
const route = useRoute();
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";


const store = useStore();

onMounted(() => {
  store.state.isAbsolute = false;
  setNavPills();
  setTooltip();
  user_id.value = route.params.user_id; // Supondo que o ID seja passado como um parâmetro na URL
  console.log("USER IDD",user_id);
  if (user_id.value) {
    loadProfileData(); // Carregar dados apenas se estiver editando
  }
  // Chama a função updateHeight para ajustar a altura do textarea ao carregar a tela
  const textareas = document.querySelectorAll('textarea');
  textareas.forEach(textarea => updateHeight({ target: textarea }));
});

const user_id = ref(null);
const autoHeight = ref('auto');
const email = ref('');
const companyName = ref('');
const companyDescription = ref('');
const keyInfo = ref('');
const virtualAssistantName = ref('');
const numSecondsWaitResponse = ref('');
const isLoading = ref(false);
const errorMessage = ref('');
const passwordHash = ref('');
import apiClient from '../axiosConfig.js';
import ArgonAlert from "@/components/ArgonAlert.vue";

const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

// Função para carregar os dados do perfil
async function loadProfileData() {
  if (!user_id.value) return; // Sair se não estiver editando
  try {
    isLoading.value = true;
    const response = await apiClient.post("/api/reseller/load_user/", {
      user_id: user_id.value,
    });
    const data = response.data.resp;
    email.value = data.email;
    companyName.value = data.companyName;
    companyDescription.value = data.companyDescription;
    keyInfo.value = data.keyInfo;
    virtualAssistantName.value = data.virtualAssistantName;
    numSecondsWaitResponse.value = data.numSecondsWaitResponse;
  } catch (error) {
    errorMessage.value = 'Erro ao carregar os dados.';
    setTimeout(() => {
      removeShowClass();
    }, 5000);
  } finally {
    isLoading.value = false;
  }
}

// Função para calcular a altura do textarea dinamicamente
function updateHeight(event) {
  const textarea = event.target;
  textarea.style.height = 'auto';
  const heightWithMargin = textarea.scrollHeight + 15;
  textarea.style.height = `${heightWithMargin}px`;
}

// Função de validação de e-mail
function validateEmail(email) {
  // Expressão regular para validar e-mail
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

// Função de validação de senha
function validatePassword(password) {
  // Verifica se a senha tem pelo menos 8 caracteres
  return password.length >= 6;
}

// Função de validação de campos vazios
function validateNotEmpty(value) {
  return value.trim() !== ''; // Verifica se o valor não está vazio após remover espaços em branco
}

// Função para limpar a mensagem de erro
const clearErrorMessage = () => {
  removeShowClass(); // Define a mensagem de erro como vazia
};

const removeShowClass = () => {
  const alertElement = document.querySelector('.alertaErroCadastro');
  if (alertElement) {
    alertElement.classList.remove('show');
  }
};

// Função para salvar os dados do perfil
const saveProfileData = async () => {
  try {
    // Verificação de campos vazios
    if (!validateNotEmpty(companyName.value) || !validateNotEmpty(companyDescription.value) || !validateNotEmpty(keyInfo.value) || !validateNotEmpty(virtualAssistantName.value) || !validateNotEmpty(numSecondsWaitResponse.value)) {
      errorMessage.value = 'Por favor, preencha todos os campos.';
      setTimeout(() => {
        removeShowClass();
      }, 5000);
      return;
    }

    // Validação de e-mail
    if (!validateEmail(email.value)) {
      errorMessage.value = 'Por favor, insira um e-mail válido.';
      setTimeout(() => {
        removeShowClass();
      }, 5000);
      return;
    }

    // Validação de senha
    if (!validatePassword(passwordHash.value)) {
      errorMessage.value = 'A senha deve ter pelo menos 6 caracteres.';
      setTimeout(() => {
        removeShowClass();
      }, 5000);
      return;
    }

    isLoading.value = true;
    var payload = {
      email: email.value,
      companyName: companyName.value,
      companyDescription: companyDescription.value,
      keyInfo: keyInfo.value,
      virtualAssistantName: virtualAssistantName.value,
      numSecondsWaitResponse: numSecondsWaitResponse.value,
      passwordHash: passwordHash.value,
    };
    if (user_id.value) {
       payload.user_id = user_id.value;
       await apiClient.post(`/api/reseller/save_user/`, payload);
      store.dispatch('triggerNotification', {
        message: 'Operação realizada com sucesso!',
        type: 'success' // ou 'error' para erros
      });
      router.push("/principal");
    } else {
       var resp = await apiClient.post(`/api/reseller/insert_user/`, payload);
       router.push("/cliente/"+resp.data.user_id);
    }
  //location para o /principal
  //router.push("/principal");
  } catch (error) {
    errorMessage.value = 'Erro ao realizar a requisição.';
    setTimeout(() => {
      removeShowClass();
    }, 5000);
  } finally {
    isLoading.value = false;
  }
};

onBeforeMount(() => {
  store.state.imageLayout = "profile-overview";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
});
onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.imageLayout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
});
loadProfileData();
</script>
<template>
    <div class="py-4 container-sm">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Editar perfil</p>
              </div>
            </div>
            <div class="card-body">
              <form @submit.prevent="saveProfileData">
              <p class="text-uppercase text-sm">Informações do usuário</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="email-input" class="form-control-label">E-mail</label>
                  <argon-input v-model="email" id="email-input" type="email" value="email@example.com" />
                </div>
                <div class="col-md-6">
                  <label for="password-hash-input" class="form-control-label">Senha</label>
                  <argon-input v-model="passwordHash" id="password-hash-input" type="password" value="" />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="company-description-input" class="form-control-label">Descrição da Empresa</label>
                  <textarea v-model="companyDescription" class="form-control" id="company-description-input" rows="3" :style="{ height: autoHeight }" @input="updateHeight"></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="key-info-input" class="form-control-label">Informações Chave</label>
                  <textarea v-model="keyInfo" class="form-control" id="key-info-input" rows="3" :style="{ height: autoHeight }" @input="updateHeight"></textarea>
                </div>
                <div class="col-md-5">
                  <label for="company-name-input" class="form-control-label">Nome da Empresa</label>
                  <argon-input v-model="companyName" id="company-name-input" type="text" value="" maxlength="200" />
                </div>
                <div class="col-md-5">
                  <label for="virtual-assistant-name-input" class="form-control-label">Nome do Assistente Virtual</label>
                  <argon-input v-model="virtualAssistantName" id="virtual-assistant-name-input" type="text" value="" maxlength="100" />
                </div>
                <div class="col-md-2">
                  <label for="response-time-input" class="form-control-label">Segundos para Responder</label>
                  <argon-input v-model="numSecondsWaitResponse"  id="response-time-input" type="number" value="" />
                </div>
                <!-- botao salvar colspan de 3 -->
                <div class="col-md-4 mx-auto mt-4 col-span-3 d-flex justify-content-center">
                  <argon-button :disabled="isLoading">
                    <span v-if="isLoading">Salvando...</span>
                        <span v-else>Salvar</span>
                      </argon-button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Alerta flutuante para exibir mensagens de erro -->
    <div :class="{ 'show': errorMessage }" class="alert alert-danger fade text-white alertaErroCadastro" role="alert" style="position: fixed; top: 18px; right: 30px; z-index: 10000">
      {{ errorMessage }}
    </div>
</template>
