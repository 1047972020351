import { createStore } from "vuex";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    notification: {
      message: '',
      type: '', // Por exemplo: 'success', 'error'
      show: false,
    },
    layout: "default",
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    sidebarMinimize(state) {
      let sidenav_show = document.querySelector("#app");
      if (state.isPinned) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    SHOW_NOTIFICATION(state, { message, type }) {
      state.notification.message = message;
      state.notification.type = type;
      state.notification.show = true;
    },
    HIDE_NOTIFICATION(state) {
      state.notification.show = false;
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    // Suas outras actions...
    triggerNotification({ commit }, { message, type }) {
      commit('SHOW_NOTIFICATION', { message, type });
      setTimeout(() => {
        commit('HIDE_NOTIFICATION');
      }, 3000); // A notificação desaparece após 3 segundos
    },
  },
  getters: {},
});
