<script setup>
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import  router  from '../router'; // Verifique o caminho correto para o seu arquivo de configuração do router
import axios from 'axios';
import ArgonAlert from "@/components/ArgonAlert.vue"; // Importando axios
const body = document.getElementsByTagName("body")[0];
const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;

const store = useStore();
const isLoading = ref(false);
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

const email = ref('');
const password = ref('');
const errorMessage = ref('');



const handleLogin = async () => {
  try {
    isLoading.value = true;
    const response = await axios.post(apiEndpoint+"/api/reseller/login/", {
      email: email.value,
      password: password.value,
    });
    isLoading.value = false;

    // Exemplo de como lidar com a resposta
    if (response.data.success) {
      // Armazenar o token de acesso, se houver
      const accessToken = response.data.accessToken;
      // Salvar o token no Vuex, localStorage, ou onde preferir
      localStorage.setItem('accessToken', accessToken);
      // Redirecionar o usuário ou tomar outras ações necessárias
      //redireciona para o "/"
      router.push("/principal");
      console.log('Login bem-sucedido!');
    } else {

      errorMessage.value = response.data.error;
      // Lidar com falhas no login
      console.error('Credenciais inválidas!');
    }
  } catch (error) {
    isLoading.value = false;
    console.error('Erro no login:', error);
  }
};
</script>
<template>
<!--  <div class="container top-0 position-sticky z-index-sticky">-->
<!--    <div class="row">-->
<!--      <div class="col-12">-->
<!--        <navbar-->
<!--          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"-->
<!--          v-bind:darkMode="true"-->
<!--          isBtn="bg-gradient-success"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Entrar</h4>
                  <p class="mb-0">Digite seu e-mail e senha para entrar</p>
                </div>
                <div class="card-body">
                  <ArgonAlert v-if="errorMessage" color="danger" >
                    {{ errorMessage }}
                  </ArgonAlert>
                  <form role="form" @submit.prevent="handleLogin">
                    <div class="mb-3">
                      <argon-input
                          v-model="email"
                          id="email"
                          type="email"
                          placeholder="Email"
                          name="email"
                          size="lg"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                          v-model="password"
                          id="password"
                          type="password"
                          placeholder="Password"
                          name="password"
                          size="lg"
                      />
                    </div>
<!--                    <argon-switch id="rememberMe" name="remember-me"-->
<!--                      >Remember me</argon-switch-->
<!--                    >-->

                    <div class="text-center">
                      <argon-button :disabled="isLoading"
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        type="submit"
                        ><template v-if="isLoading">
                        <!-- Se estiver carregando, mostra o texto de loading -->
                        <span>Carregando...</span>
                      </template>
                        <template v-else>
                          <!-- Se não estiver carregando, mostra o texto normal -->
                          Entrar
                        </template></argon-button
                      >
                    </div>
                  </form>
                </div>
<!--                <div class="px-1 pt-0 text-center card-footer px-lg-2">-->
<!--                  <p class="mx-auto mb-4 text-sm">-->
<!--                    Don't have an account?-->
<!--                    <a-->
<!--                      href="javascript:;"-->
<!--                      class="text-success text-gradient font-weight-bold"-->
<!--                      >Sign up</a-->
<!--                    >-->
<!--                  </p>-->
<!--                </div>-->
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  Deixe a nossa IA trabalhar para os seus clientes!
                </h4>
                <p class="text-white position-relative">
                  A inteligência artificial não é mais uma ideia futurista, é uma realidade que está transformando o mundo em que vivemos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>
