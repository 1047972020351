import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Principal from "../views/Principal.vue";
import requireAuth from "../middleware/auth";
import Cliente from "@/views/Cliente.vue"; // Importe o middleware
import MinhaConta from "@/views/MinhaConta.vue"; // Importe o middleware

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/principal",
  },
  {
    path: "/principal",
    name: "Principal",
    component: Principal,
    meta: { requiresAuth: true } // Marque a rota como requerindo autenticação
  },
  {
    path: "/cliente/:user_id?", // O "?" torna o parâmetro "id" opcional
    name: "Cliente",
    component: Cliente,
    meta: { requiresAuth: true }
  },
  {
    path: "/minha-conta/", // O "?" torna o parâmetro "id" opcional
    name: "Minha Conta",
    component: MinhaConta,
    meta: { requiresAuth: true }
  },

    //aqui pra baixo é tudo automatico e antigo
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
];
// Adicione um guarda de rota global para verificar a autenticação em rotas protegidas


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    requireAuth(to, from, next); // Verifique a autenticação apenas para rotas que exigem autenticação
  } else {
    next(); // Se a rota não exigir autenticação, continue normalmente
  }
});
export default router;
