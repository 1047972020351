import axios from 'axios';

const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
// Cria uma instância do Axios com configurações padrões
const apiClient = axios.create({
    baseURL: apiEndpoint, // Base URL
    // outras configurações podem ser aqui
});

// Configura um interceptador de requisições para incluir o token
apiClient.interceptors.request.use((config) => {
    // Obtenha o token de algum lugar (localStorage como exemplo)
    const token = localStorage.getItem('accessToken');

    // Se o token existir, adicione-o ao cabeçalho de Autorização
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    // Faz algo com o erro de requisição
    return Promise.reject(error);
});

export default apiClient;
